<template>
    <div class="th-back-top" v-show="flag" @click="clickHidden">
        <div class="bg-back2top2"></div>
    </div>
</template>
<script>
export default {
    name:"BackTop",
    data(){
        return{
            flag:false
        }
    },
    mounted(){
        //获取页面可视化高度

       window.addEventListener("scroll",()=>{
        //  console.log(document.documentElement.scrollTop);  
        //获取页面滚动的高度
           let scrollTop = document.documentElement.scrollTop;
           if(scrollTop > 300){
               this.flag = true;
           }else{
               this.flag = false;
           }

       }) 
    },
    methods:{
       clickHidden(){
            (function smoothUp() {
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                const STEP = 50
                if (scrollTop > 0) {
                  if (scrollTop - 0 >= STEP) {
                      scrollTop -= STEP
                  } else {
                      scrollTop = 0
                  }
                  document.body.scrollTop = scrollTop
                  document.documentElement.scrollTop = scrollTop
                  requestAnimationFrame(smoothUp)
                }
            })()
       }
    }
}
</script>
<style scoped>

.th-back-top{
    position:fixed;
    right:15px;
    bottom:104px;
    z-index:99;
    opacity:0.8;
    text-align:right;
}
.bg-back2top2 {
    width: 57px;
    height: 57px;
    background: linear-gradient(105deg,#73e0a9, #5b68df 100%);
    position: relative;
}
.bg-back2top2::after {
    content: '';
    width: 20px;
    height: 20px;
    border-top: 1px #fff solid;
    border-left: 1px #fff solid;
    position: absolute;
    top: 40%;
    left: 35%;
    transform: rotate(45deg);
}
</style>