import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
        path: '/',
        name: 'debt',
        component: () =>
            import ('../views/debt.vue')
    }, {
        path: '/about-us',
        name: 'about',
        component: () =>
            import ('../views/About.vue')
    }, {
        path: '/contact-us',
        name: 'contactus',
        component: () =>
            import ('../views/ContactUs.vue')
    }, {
        path: '/privacy-statement',
        name: 'privacystatement',
        component: () =>
            import ('../views/PrivacyStatement.vue')
    }, {
        path: '/cookies-statement',
        name: 'cookiesstatement',
        component: () =>
            import ('../views/CookiesStatement.vue')
    }, {
        path: '/our-thinking',
        name: 'ourthinking',
        component: () =>
            import ('../views/OurThinking.vue')
    }, {
        path: '/coordinated-development',
        name: 'coordinateddevelopment',
        component: () =>
            import ('../views/CoordinatedDevelopment.vue')
    }, {
        path: '/news-center',
        name: 'newscenter',
        component: () =>
            import ('../views/NewsCenter.vue')
    }, {
        path: '/subsidiary-company',
        name: 'subsidiarycompany',
        component: () =>
            import ('../views/SubsidiaryCompany.vue')
    }, {
        path: '/investor-relations',
        name: 'investorrelations',
        component: () =>
            import ('../views/InvestorRelations.vue')
    }, {
        path: '/news-details',
        name: 'newsdetails',
        component: () =>
            import ('../views/NewsDetails.vue')
    }]
}, ]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router