<template>
  <div class="footer">
      <div class="content_box container_width">
          <div class="foot_logo">
              <img src="../assets/imgs/home/Logo.png" alt="">
              <!-- <div class="foot_introduce">TheFund is the world's first non-fungible token (NFT) marketplace for both digital and physical assets(公司介绍)</div> -->
          </div>
          <div class="foot_navigation">
              <ul class="foot_link">
                  <span class="title">链接</span>
                  <li><a href="/privacy-statement">隐私政策</a></li>
                  <li><a href="/cookies-statement">Cookies</a></li>
                  <li><a href="/our-thinking">我们的思考</a></li>
              </ul>
              <ul class="foot_news">
                  <li class="title">最新新闻</li>
                  <li>
                      <div>春暖花开丨一起向未来</div>
                      <div>2022年5月10号</div>
                  </li>
                  <li>
                      <div>一封来自客户的感谢信</div>
                      <div>2022年5月10号</div>
                  </li>
              </ul>
              <ul class="foot_connect">
                  <li class="title">联系我们</li>
                  <li>
                      <span>地址: </span>
                      <span>湖北省武汉市洪山区雄楚大道万科中心502</span>
                  </li>
                  <li>
                      <span>电话: </span>
                      <span>13588762602</span>
                  </li>
                  <li> 
                      <span>E-mail: </span>
                      <span>boss@haianyd.com</span>
                  </li>
              </ul>
          </div>
      </div>
      <div style="width:100%;margin:0 auto; padding:20px 0;text-align: center;display: flex;justify-content: center;">
         <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006132" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="../../public/beiantubiao.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">鄂公网安备 42018502006132号</p>
         </a>
         <a href="https://beian.miit.gov.cn/#/Integrated/index">
            <p style="height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"> | 鄂ICP备2022011370号</p>
         </a>
       </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.footer {
    background-color: #161616;
    padding: 60px 0 30px;
    .content_box {
        @include flexrl();
        .foot_logo {
            width: 22%;
            img {
                margin-bottom: 20px;
            }
            .foot_introduce {
                font-size: 14px;
                text-align: justify;
                color: #ffffff;
                line-height: 24px;
            }
        }
        .foot_navigation {
            width: 60%;
            @include flextoprl();
            font-size: 14px;
            text-align: left;
            color: #ffffff;
            line-height: 14px;
            .foot_link {
                @include flexleft();
                li {
                    cursor: pointer;
                    display: inline-block;
                    position: relative;
                }
                a {
                    color: #fff;
                }
                li:not(:first-child) {
                    margin-bottom: 15px;
                }
                li::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scale3d(0,2,1);
                    height: 2px;
                    bottom: -3px;
                    left: 0;
                    background: linear-gradient(91deg,#73e0a9, #5b68df 100%);
                    border-radius: 50px;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
                li:hover::before{
                    transform: scale3d(1,1,1);
                    transform-origin: bottom left;
                }
            }
            .foot_news {
                li:not(:first-child) {
                    margin-bottom: 20px;
                    div:last-child {
                        margin-top: 10px;
                        font-size: 10px;
                        color: #c4c4c4;
                        line-height: 10px;
                    }
                }
            }
            .foot_connect {
                li:not(:first-child) {
                    margin-bottom: 30px;
                }
            }
            .title {
                font-size: 24px;
                font-weight: bold;
                color: #ffffff;
                line-height: 24px;
                margin-bottom: 25px;
            }
        }
    }
    .foot_policy {
        margin-top: 100px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 26px;
        letter-spacing: -0.03px;
    }
}
@media screen and (max-width: 768px) {
    .footer {
        .content_box {
            flex-direction: column;
            .foot_logo {
                width: 90%;
                margin-bottom: 20px;
            }
            .foot_navigation {
                width: 90%;
                ul {
                    width: 50%;
                }
                .foot_news {
                    display: none;
                }
            }
        }
    }
}

</style>