<template>
  <div class="home">
    <nav-header></nav-header>
    <router-view></router-view>
    <offer-carousels></offer-carousels>
    <client-height></client-height>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavHeader from "@/components/NavHeader";
import NavFooter from "@/components/NavFooter";
import OfferCarousels from "@/components/OfferCarousels";
import ClientHeight from '@/components/ClientHeight.vue';

export default {
  name: 'Home',
  components: {
    NavHeader,
    NavFooter,
    OfferCarousels,
    ClientHeight
  }
}
</script>
