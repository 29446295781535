<template>
  <div class="header" v-bind:class="{ headermover: flag }">
    <div class="logo-container container_width">
      <a href="/">
        <img src="../assets/imgs/home/Logo.png" alt="logo" />
      </a>
      <ul class="navigation-container">
        <li><a href="/about-us">关于我们</a></li>
        <li><a href="/coordinated-development">服务项目</a></li>
        <li><a href="/subsidiary-company">旗下公司</a></li>
        <li><a href="/news-center">新闻中心</a></li>
        <li><a href="/investor-relations">投资者关系</a></li>
        <input
          type="text"
          name=""
          class="header_search"
          v-bind:class="{ search_input: flag }"
        />
      </ul>
    </div>
    <div class="m-logo-container">
      <a href="/" class="logo">
        <img src="../assets/imgs/home/m-Logo.webp" alt="logo" />
      </a>
      <div class="outer-menu">
        <input class="checkbox-toggle" type="checkbox" />
        <div class="hamburger">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="menu">
          <div>
            <div>
              <ul>
                <li><a href="/about-us">关于我们</a></li>
                <li><a href="/coordinated-development">服务项目</a></li>
                <li><a href="/subsidiary-company">旗下公司</a></li>
                <li><a href="/news-center">新闻中心</a></li>
                <li><a href="/investor-relations">投资者关系</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  data() {
    return {
      flag: false,
    };
  },
  mounted() {
    //获取页面可视化高度
    window.addEventListener("scroll", () => {
      //获取页面滚动的高度
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 200) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    });
  },
};
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  .logo-container {
    @include flexrl();
    padding: 10px 0;
    img {
      max-height: 74px;
    }
    .navigation-container {
      @include flexrl();
      font-size: 16px;
      li {
        cursor: pointer;
        margin-right: 40px;
        display: inline-block;
        position: relative;
        a {
          color: #fff;
        }
      }
      li::before {
        content: "";
        position: absolute;
        width: 100%;
        transform: scale3d(0, 2, 1);
        height: 2px;
        bottom: -3px;
        left: 0;
        background: linear-gradient(91deg, #73e0a9, #5b68df 100%);
        border-radius: 50px;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      li:hover::before {
        transform: scale3d(1, 1, 1);
        transform-origin: bottom left;
      }
      .header_search {
        width: 0px;
        height: 40px;
        border-radius: 20px;
        border: none;
        background-color: transparent;
        color: #fff;
        border: none;
        padding: 0 30px;
        background: url("../assets/imgs/home/search.png") no-repeat;
        background-position: 10px 5px;
        transition: width 400ms ease, background 400ms ease;
      }
      .header_search:focus {
        border: solid 1px transparent;
        border-image: linear-gradient(105deg, #73e0a9, #5b68df 100%) 2 2;
        width: 250px;
        height: 40px;
        background-position: 260px 5px;
      }
    }
  }
  .m-logo-container {
    display: none;
  }
}
.headermover {
  background-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 12px rgb(0 0 0 / 9%);
  .logo-container {
    li {
      a {
        color: #000 !important;
      }
    }
  }
}
.search_input {
  color: #000 !important;
}
@media screen and (max-width: 1160px) {
  .header {
    .logo-container {
      display: none;
    }
    .m-logo-container {
      position: relative;
      @include flexrl();
      .logo {
        @include flexc();
        h1 {
          font-size: 30px;
          font-weight: bold;
          color: #161616;
          line-height: 11px;
          margin-left: 20px;
        }
        img {
          margin-left: 20px;
          width: 90px;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
}
@media screen and (max-width: 768px) {
  .header {
    .m-logo-container {
      .logo {
        h1 {
          font-size: 20px;
        }
        img {
          width: 40px;
          padding: 10px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .header {
    .m-logo-container {
      .logo {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-size: 3em;
  text-align: center;
}

.outer-menu {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  z-index: 1;

  .checkbox-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 30px;
    height: 30px;
    opacity: 0;

    &:checked {
      & + .hamburger {
        > div:not(:nth-child(2)) {
          display: none;
        }
        > div {
          transform: rotate(135deg);

          &:before,
          &:after {
            top: 0;
            transform: rotate(90deg);
          }

          &:after {
            opacity: 0;
          }
        }
      }

      & ~ .menu {
        pointer-events: auto;
        visibility: visible;
        background: #000;
        opacity: 0.8;

        > div {
          transform: scale(1);
          transition-duration: 0.75s;

          > div {
            opacity: 1;
            transition: opacity 0.4s ease 0.4s;
          }
        }
      }
    }

    &:hover + .hamburger {
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &:checked:hover + .hamburger > div {
      transform: rotate(225deg);
      background: #d89320;
    }
    &:checked:hover + .hamburger > div:not(:nth-child(2)) {
      display: none;
    }
  }

  .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 30px;
    height: 30px;
    background: fade(#2196f3, 75%);
    border-radius: 0 0.12em 0.12em 0;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: 2px;
      background: #d89320;
      transition: all 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -60px / 6;
        left: 0;
        width: 100%;
        height: 2px;
        background: #d89320;
        transition: all 0.4s ease;
      }

      &:after {
        top: 60px / 6;
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 100vw;
      height: 100vh;
      color: #fefefe;
      background: fade(#2962ff, 97%);
      border-radius: 50%;
      transition: all 0.4s ease;
      flex: none;
      transform: scale(0);
      backface-visibility: hidden;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      justify-content: center;

      > div {
        text-align: center;
        max-width: 90vw;
        max-height: 100vh;
        opacity: 0;
        transition: opacity 0.4s ease;
        overflow-y: auto;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;

        > ul {
          list-style: none;
          padding: 0 1em;
          margin: 0;
          display: block;
          max-height: 100vh;

          > li {
            padding: 0;
            margin: 1em;
            font-size: 24px;
            display: block;

            > a {
              position: relative;
              display: inline;
              cursor: pointer;
              transition: color 0.4s ease;

              &:hover {
                color: darken(#fefefe, 10%);

                &:after {
                  width: 100%;
                }
              }

              &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: -0.15em;
                left: 0;
                width: 0;
                height: 2pxt;
                background: darken(#fefefe, 10%);
                transition: width 0.4s ease;
              }
            }
          }
        }
      }
    }
  }
}
</style>
