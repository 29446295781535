<template>
    <div class="offser_swiper_box container_width">
      <swiper :options="offerSwiperOption" class="offser_swiper swiper-no-swiping">
        <swiper-slide>
          <img src="../assets/imgs/home/home_offer_1.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/imgs/home/home_offer_2.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/imgs/home/home_offer_3.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/imgs/home/home_offer_4.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/imgs/home/home_offer_5.png" alt="">
        </swiper-slide>
      </swiper>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: "offer-carousels",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
      return {
        offerSwiperOption: {
            loop: true,
            autoplay: {
            delay: 0,
            disableOnInteraction: false
            },
            speed: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
            slidesPerView : 5,
            loopedSlides: 7,
            spaceBetween: 0,
        },
      }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
    .offser_swiper_box {
       padding: 90px 0 90px;
      .offser_swiper {
        .swiper-wrapper {
          -webkit-transition-timing-function: linear; /*之前是ease-out*/
          -moz-transition-timing-function: linear;
          -ms-transition-timing-function: linear;
          -o-transition-timing-function: linear;
          transition-timing-function: linear;
          align-items: center;
        }
        .swiper-slide {
          img {
              width: 100%;
          }
        }
      }
    }
</style>